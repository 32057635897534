import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FormEventAnalytics } from "../../utils/analytics"
import { setUTMSource } from "../../utils/storage"
import scrollTo from "gatsby-plugin-smoothscroll"

class Exams extends React.Component {
  componentDidMount() {
    setUTMSource()
  }

  render() {
    return (
      <Layout>
        <SEO
          title={"Online Exams"}
          titleDesc={
            "Fuse Classroom comprises proctoring tools, plagiarism detection, digital flashcards that helps manage online exams more conveniently."
          }
          titleKeywords={
            "plagiarism checker, online exams, online test, plagiarism detection, study materials, online exam system, online exam builder, proctoring tool, ai based online exam"
          }
          fbDes={" Fuse Classroom’s Online Exams helps to build and manage exams more conveniently."}
          twitDesc={" Fuse Classroom’s online exams helps to build and manage exams more conveniently."}
          seoLink={"platform/online-exams/"}
        />

        {/* top section */}
        <section role="top--section" className="bg-light-green overflow-hidden">
          {/* content */}
          <article className="container">
            <Row className="align-items-center py-4">
              <Col md="6" className="pt-md-5 pb-5">
                <div className="text-green">
                  <h1>Online Exams</h1>
                  <div className="h4 mt-4 text-black font-weight-normal">
                    The Online Exams feature gives students access to a library of study materials, such as digital
                    flashcards and gamified exams. These study materials ensure students are prepared for their quizzes,
                    assignments, and exams.
                  </div>

                  <div className="main-cta mt-4">
                    <Link
                      to="/request-for-more-info/"
                      className="btn btn-success mr-3 btn-lg font-weight-semibold"
                      // onClick={() => FormEventAnalytics("Exams_Top_Request_Demo")}
                      state={{ page: "Exams" }}
                      id="request--for-more-info__top"
                    >
                      REQUEST FOR MORE INFO
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="fluid-left d-none d-md-block mt-3 mb-3">
                  <img
                    src="/images/platform/online-exams/online-exams.webp"
                    alt="Online Exams"
                    width="640"
                    height="auto"
                  />
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* top section end */}

        {/* Online Exams */}
        <section className="pb-5 d-none d-md-block" id="ai-info">
          <article className="container ai-features">
            <Row>
              <Col sm="12" className="py-4 mb-5 text-center">
                <div className="text-center h1">
                  Online <br /> Exams
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Proctoring-Tools")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img src="/images/icon/proctoring-tools.svg" alt="Proctoring Tools" width="45" height="auto" />
                      </div>
                      <Card.Title className="ai-title">Proctoring Tools</Card.Title>
                      <div className="small">
                        This feature ensures that online exams are protected from cheating of any kind.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Plagiarism-Detection")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/plagirarism-detection.svg"
                          alt="Plagiarism Detection"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Plagiarism Detection</Card.Title>
                      <div className="small">
                        Allows for peer evaluation of student work, academic papers, and other sources.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#AI-Exam-Builder")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/exam-creator.svg"
                          alt="AI Exam Builder"
                          className="img-fluid"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">AI Exam Builder</Card.Title>
                      <div className="small">
                        Creates exams from scratch and allows you to import exams from third party databases.
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Digital-Flashcards")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/ai-assisted-learning.svg"
                          alt="Digital Flashcards"
                          className="img-fluid"
                          width="640"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Digital Flashcards</Card.Title>
                      <div className="small">Study materials available whenever students need them.</div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>

              <Col lg="2" md="6" xs="4" className="my-3">
                <button onClick={() => scrollTo("#Gamified-Exams")} className="platform-ai">
                  <Card>
                    <Card.Body>
                      <div className="ai-image">
                        <img
                          src="/images/icon/difficulty-prediction.svg"
                          alt="Gamified Exams"
                          width="45"
                          height="auto"
                        />
                      </div>
                      <Card.Title className="ai-title">Gamified Exams</Card.Title>
                      <div className="small">Students can retain important information for upcoming exams. </div>
                    </Card.Body>
                    <Card.Footer>
                      <img
                        src="/images/implementation/chevron-down.svg"
                        className="img-fluid"
                        width="13"
                        height="auto"
                      />
                    </Card.Footer>
                  </Card>
                </button>
              </Col>
            </Row>
          </article>
        </section>
        {/* Online Exams End */}

        {/* Proctoring Tools */}
        <section id="Proctoring-Tools" className="py-5">
          <article className="container py-2">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/online-exams/proctoring.webp"
                  alt="Proctoring Tools"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/proctoring-tools.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Proctoring Tools</h2>
                  <div className="h5 text-black font-weight-normal">
                    Our proctoring tools ensure that online exams are protected from cheating of any kind. Our platform
                    is embedded with mouse tracking and video streaming features to ensure that no suspicious behavior
                    occurs throughout the course of the exam. Proctors are enabled to intervene as needed to address any
                    issues that may arise.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* fuse classroom Exam end */}

        {/* Plagiarism Detection */}
        <section id="Plagiarism-Detection" className="py-5 bg-80 left">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/online-exams/plagiarism.webp"
                  alt="Plagiarism Detection"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img
                      src="/images/icon/plagirarism-detection.svg"
                      alt="Plagiarism Detection"
                      width="45"
                      height="auto"
                    />
                  </div>
                  <h2 className="my-4">Plagiarism Detection</h2>
                  <div className="h5 text-black font-weight-normal">
                    Our plagiarism tool compares submitted assignments against other student work, academic papers, and
                    other sources to ensure that students are held to the level of academic integrity required by your
                    institution.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Plagiarism Detection end */}

        {/* AI Exam Builder */}
        <section id="AI-Exam-Builder" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/online-exams/ai-exam.webp"
                  alt="AI Exam Builder"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/exam-creator.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">AI Enabled Exam Builder</h2>
                  <div className="h5 text-black font-weight-normal">
                    Our AI Enabled Exam Builder helps create or adapt exams at scale. This tool ensures you won't need
                    to create exams from scratch and gives you the option to import exams from third party databases.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* AI Exam Builder end) */}

        {/* Digital Flashcards */}
        <section className="py-5 bg-80 left" id="Digital-Flashcards">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6 }}>
                <img
                  src="/images/platform/online-exams/digital-flashcards.webp"
                  alt="Digital Flashcards"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/ai-assisted-learning.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Digital Flashcards</h2>
                  <div className="h5 text-black font-weight-normal">
                    Both instructors and students have access to flashcards for their classes. Flashcards can be
                    created, shared, and downloaded so students can return to them whenever they need to.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* Digital Flashcards end */}

        {/* Gamified Exams */}
        <section id="Gamified-Exams" className="py-5">
          <article className="container">
            <Row className="align-items-center">
              <Col md={{ span: 6, offset: 1, order: 2 }}>
                <img
                  src="/images/platform/online-exams/gamified-exams.webp"
                  alt="Gamified Exams"
                  className="img-fluid"
                  width="640"
                  height="auto"
                />
              </Col>
              <Col md={{ span: 5, order: 1 }}>
                <div className="my-4">
                  <div className="mb-2">
                    <img src="/images/icon/difficulty-prediction.svg" alt="icon" width="45" height="auto" />
                  </div>
                  <h2 className="my-4">Gamified Exams</h2>
                  <div className="h5 text-black font-weight-normal">
                    This automated tool provides students practice exams as a way to retain information for upcoming
                    exams. These exams are a less stressful alternative to problem sets and practice problems to avoid
                    study fatigue.
                  </div>
                </div>
              </Col>
            </Row>
          </article>
        </section>
        {/* AI Exam Builder end) */}

        {/* call to action */}
        <section className="py-5">
          <article className="container">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="rounded border border-success p-4 text-center">
                  <span className="d-inline-block mr-md-5 mb-0 h4 font-weight-bold">
                    Interested in more information about our platform?
                  </span>
                  {/* <button className="btn btn-success" onClick={() => this.setState({modalShow: true})}>Request a Demo</button> */}
                  <Link
                    to="/request-for-more-info/"
                    className="btn btn-success mt-3 mt-xl-0"
                    // onClick={() => FormEventAnalytics("Exams_Bottom_Request_Demo")}
                    state={{ page: "Exams" }}
                    id="request--for-more-info__bottom"
                  >
                    REQUEST FOR MORE INFO
                  </Link>
                </div>
              </Col>
            </Row>
          </article>
        </section>
      </Layout>
    )
  }
}

export default Exams
